import React from 'react';
import { Helmet } from 'react-helmet';

const seoValues = {
  title: 'Somali Islamic Society of BC',
  titleTemplate: 'SISBC',
  description: "The Somali Islamic Society of BC is a non profit organization ",
  url: 'https://www.sisbc.ca', // No trailing slash allowed!
  // image: "/images/snape.jpg", // Path to your image you placed in the 'static' folder
  // twitterUsername: "@occlumency",
};

const Seo = () => {
  return (
    <Helmet title={seoValues.title} titleTemplate={seoValues.title}>
      <meta name="description" content={seoValues.titleTemplate} />
      {/* <meta name="image" content={seo.image} /> */}

      {seoValues.url && <meta property="og:url" content={seoValues.url} />}

      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}

      {seoValues.title && <meta property="og:title" content={seoValues.title} />}

      {seoValues.description && (
        <meta property="og:description" content={seoValues.description} />
      )}

      {/* {seo.image && <meta property="og:image" content={seo.image} />} */}

      {/* <meta name="twitter:card" content="summary_large_image" /> */}

      {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}

      {seoValues.title && <meta name="twitter:title" content={seoValues.title} />}

      {seoValues.description && (
        <meta name="twitter:description" content={seoValues.description} />
      )}

      {/* {seo.image && <meta name="twitter:image" content={seo.image} />} */}
    </Helmet>
  );
};

export default Seo;
